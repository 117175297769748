import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import moment from "moment";
function Houses() {
  const [houses, setHouses] = useState({ rows: [] });
  const [region, setRegions] = useState({ rows: [] });
  const [users, setUsers] = useState({ rows: [] });
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newCharge, setNewCharge] = useState(null);
  const [newBillingDate,setNewBillingDate ]= useState(null);
  const [newUser, setNewUser] = useState(null);
  const [HouseNumber, setHouseNumber] = useState("");
  const [HouseCharge, setHouseCharge] = useState(0);
  const [Building, setBuilding] = useState("");
  const [paginationSize, updatepaginationSize] = React.useState(5);
  const [pageStartIndex, updatepageStartIndex] = React.useState(0);
  const [offset, updatepaginationOffset] = React.useState(5);
  const [page, updatePage] = React.useState(0);
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  function dateFormatter(cell, row) {
    if (row.billingDate  ) {
      return (
        <span>
           { moment(row.billingDate).format("dddd, MMMM Do YYYY")}
        </span>
      );
    }
  }
  const actionsFormatter = (cell, row) => {
     // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
    return (
      <Row >
      <Button
      className=" btn-round btn-sm"
      color="primary"
      id="tooltip639225725"
      onClick={() => {
        setSelectedItem(row)
        setEditModal(true);
      }}
    >
      <i className="tim-icons  icon-pencil" /> 
    </Button>
    <Button
      className=" btn-round btn-sm"
      color="danger"
      id="tooltip639225725"
      onClick={() => {
        setSelectedItem(row)
        setDelModal(true);
      }}
    >
      <i className="tim-icons  icon-trash-simple" /> 
    </Button>
    </Row>
    );
};
const actionsFormatter1 = (cell, row) => {
   // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
  return (
    <Row >
  <Button
    className=" btn-round btn-sm"
    color="primary"
    id="tooltip639225725"
    onClick={() => {
      setOccupantEdit(true);
    }}
  >
    <i className="tim-icons  icon-pencil" /> 
  </Button>
  <Button
      className=" btn-round btn-sm"
      color="danger"
      id="tooltip639225725"
      onClick={() => {
        setSelectedUser(row)
        setDelOccupantModal(true);
      }}
    >
      <i className="tim-icons  icon-trash-simple" /> 
    </Button>
  </Row>
  );
};
  const Columns = [
    {
      dataField: "id",
      text: "House ID",
    },
    {
      dataField: "number",
      text: "Number",
    },
    {
      dataField: "charge",
      text: "Charge",
    },
    {
      dataField: "Billing Start Date",
      text: "billing Start Date",
      formatter:dateFormatter
    },
    {
      dataField: "building",
      text: "Building",
    },
    {
      dataField: "active",
      text: "Active",
    },
    
    {dataField: "actions",
    text: "Actions",
    formatter: actionsFormatter
}
  ];
  const Column2 = [
    {
      dataField: "id",
      text: "User Id",
    },
    {
      dataField: "firstName",
      text: "firstName",
    },
    {
      dataField: "lastName",
      text: "lastName",
    },
    {
      dataField: "phone",
      text: "phone",
    },
    {
      dataField: "active",
      text: "Active",
    },
    {dataField: "actions",
    text: "Actions",
    formatter: actionsFormatter1
}
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const paginationOption = {
    page: 0,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      
      
    },
    onSizePerPageChange: function (page, sizePerPage) {
      
      
    } // A numeric array is also available. the purpose of above example is custom the text
  };

  const [modal, setModal] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const [delOcupantmodal, setDelOccupantModal] = useState(false);
  const [editmodal, setEditModal] = useState(false);
  const [OccupantEditModal, setOccupantEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState("false");
  const toggle = () => setModal(!modal);
  const toggleDel = () => setDelModal(!delmodal);
  const toggleEdit = () => setEditModal(!editmodal);
  const toggleDelOccupant = () => setDelOccupantModal(!delOcupantmodal);
  const toggleOccupantEdit = () => setOccupantEdit(!OccupantEditModal);
 
  const getResource = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      console.log("use", data.data);
      setter(data.data);
    });
  };
  const getRegion = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      setter(data.data);
    });
  };
  const getUsers = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      setter(data.data);
      console.log("users", data.data);
    });
  };
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <Row style={{display:"flex", justifyContent:"space-between"}}>
        
        <p>{`House Occupants   - ${row.Resident.length}`}</p>
        <Button
      className=" btn-round btn-sm"
      color="danger"
      id="tooltip639225725"
      onClick={() => {
        setSelectedItem(row)
        setOccupantEdit(true)
      }}
    >
      Add Occupant
    </Button>
      
    </Row>
        {row.Resident ? <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                            data={ row.Resident } columns={ Column2 } /> : ''}
      </div>
    ),
  };
  const addHouse = () => {
    const data = {
      number: HouseNumber,
      building: Building,
      region: selectedRegion,
      charge: HouseCharge,
      occupant: selectedUser,
    };
    axios({
      url: window.server + "/houses",
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
      data: data,
    })
      .then(async (response) => {
        const data = response.data;
        Swal.fire({
          title: " Success!",
          text: response.data.message,
          confirmButtonText: "ok",
        });
        getResource("/houses", setHouses);
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: "Error!",
          text: error.message,
          confirmButtonText: "ok",
        });
      });
  };
  const deleteHouse = () => {
    axios({
      url: window.server + `/houses/` + selectedItem.id,
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    })
      .then(async (response) => {
        const data = response;
        Swal.fire({
          title: " Success!",
          text: response.data.message,
          confirmButtonText: "ok",
        });
        getResource("/houses", setHouses);
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: "Error!",
          text: error.message,
          confirmButtonText: "ok",
        });
      });
  };
  const deleteOccupant = () => {
    console.log("fss", {
      HouseId:selectedItem.id, 
      UserId:selectedUser.id
    })
    axios({
      url: window.server + `/Occupants`,
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
      data:{
        HouseId:selectedItem.id, 
        UserId:selectedUser.id
      }
    })
      .then(async (response) => {
        const data = response;
        Swal.fire({
          title: " Success!",
          text: response.data.message,
          confirmButtonText: "ok",
        });
        getResource("/houses", setHouses);
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: "Error!",
          text: error.message,
          confirmButtonText: "ok",
        });
      });
  };
  const updateHouse = () => {
    const data = {};
    if(newCharge) { data.charge = newCharge }
    if(newUser) { data.user = newUser}
    if(newBillingDate) { data.billingDate = moment(newBillingDate).utc()}
    axios({
      url: window.server + `/houses/` + selectedItem.id,
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
      data: data,
    })
      .then(async (response) => {
        const data = response;
        setEditModal(false);
        setOccupantEdit(false);
        Swal.fire({
          title: " Success!",
          text: response.data.message,
          confirmButtonText: "ok",
        });
        getResource("/houses", setHouses);
      })
      .catch((error) => {
        console.log("error", error);
        Swal.fire({
          title: "Error!",
          text: error.message,
          confirmButtonText: "ok",
        });
      });
  };
  useEffect(() => {
    getRegion(`/regions?page=${0}&size=${1000000000}`, setRegions);
    getResource(`/houses?page=${0}&size=${1000000000}`, setHouses);
    getUsers(`/users?page=${0}&size=${1000000000}`, setUsers);
  }, []);
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <CardTitle tag="h4">All Houses</CardTitle>
                        <Button
                          className=" btn-neutral btn-round btn-simple"
                          color="default"
                          onClick={() => toggle()}
                        >
                          {" "}
                          Add House
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ToolkitProvider
                        keyField="id"
                        data={houses.rows}
                        columns={Columns}                        
                        search
                        exportCSV
                      >
                        {(props) => (
                          <div>
                            <span style={{ padding: "5px" }}>
                              {" "}
                              <ExportCSVButton {...props.csvProps}>
                                Export CSV!!
                              </ExportCSVButton>
                            </span>

                            <span>
                              <SearchBar {...props.searchProps} />
                            </span>
                            <hr />
                            <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                              pagination={paginationFactory(paginationOption)}
                              expandRow={expandRow}
                              {...props.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal
          modalClassName="modal-black"
          isOpen={modal}
          toggle={toggle} /*  className={className} */
        >
          <ModalHeader toggle={toggle}>Add House</ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>Region</Label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setSelectedRegion(e.target.value);
                      }}
                    >
                      <option value="Select Region">- Select Region -</option>
                      {region.rows.map((el, i) => (
                        <option key={i} value={el.id}>
                          {el.Name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Building</Label>
                    <Input
                      placeholder="Building A"
                      type="text"
                      onChange={(e) => setBuilding(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>House Number</Label>
                    <Input
                      placeholder="A1"
                      type="text"
                      onChange={(e) => {
                        setHouseNumber(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Users</Label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setSelectedUser(e.target.value);
                      }}
                    >
                      <option value="Select Region">- Select Users -</option>
                      {users.rows.map((el, i) => (
                        <option key={i} value={el.id}>
                          {el.firstName}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Charge</Label>
                    <Input
                      placeholder="300"
                      type="text"
                      onChange={(e) => {
                        setHouseCharge(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-icon btn-round"
              color="primary"
              id="tooltip639225725"
              onClick={() => {
                setModal(false);
                addHouse();
              }}
            >
              <i className="tim-icons  icon-simple-add" />
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-black"
          isOpen={delmodal}
          toggle={toggleDel} /*  className={className} */
        >
          <ModalHeader toggle={toggleDel}>Confirmation</ModalHeader>
          <ModalBody>
            <p>
              Are You sure You want to delete this house ? {selectedItem._id}{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className=" btn-round"
              color="danger"
              id="tooltip639225725"
              onClick={() => {
                setDelModal(false);
                deleteHouse();
              }}
            >
              <i className="tim-icons icon-simple-remove" />
              Yes im sure
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-black"
          isOpen={delOcupantmodal}
          toggle={toggleDelOccupant} /*  className={className} */
        >
          <ModalHeader toggle={toggleDelOccupant}>Confirmation</ModalHeader>
          <ModalBody>
            <p>
              Are You sure You want to delete this Occupant ? {selectedItem.id}{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className=" btn-round"
              color="danger"
              id="tooltip639225725"
              onClick={() => {
                setDelOccupantModal(false);
                deleteOccupant();
              }}
            >
              <i className="tim-icons icon-simple-remove" />
              Yes im sure
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-black"
          isOpen={editmodal}
          toggle={toggleEdit} /*  className={className} */
        >
          <ModalHeader toggle={toggleEdit}>Editing House</ModalHeader>
          <ModalBody>
            <p>Editing House {selectedItem.id} </p>
            <Form>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>House Number</Label>
                    <InputGroup>
                      <Input
                        placeholder={selectedItem.number}
                        type="text"
                        disabled
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Charge</Label>
                    <InputGroup>
                      <Input
                        placeholder={selectedItem.charge}
                        type="text"
                        onChange={(e) => setNewCharge(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Billing Date</Label>
                    <InputGroup>
                      <Input
                        placeholder={selectedItem.billingDate}
                        type="date"
                        onChange={(e) => setNewBillingDate(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <Button
                    className=" btn-round"
                    color="danger"
                    id="tooltip639225725"
                    onClick={() => {
                      updateHouse();
                    }}
                  >
                    <i className="tim-icons icon-pencil" />
                    Update Record
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-black"
          isOpen={OccupantEditModal}
          toggle={toggleOccupantEdit} /*  className={className} */
        >
          <ModalHeader toggle={toggleOccupantEdit}>Add Occupant</ModalHeader>
          <ModalBody>
            <p>Editing House {selectedItem.id} </p>
            <Form>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>House Number</Label>
                    <InputGroup>
                      <Input
                        placeholder={selectedItem.number}
                        type="text"
                        disabled
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <FormGroup>
                    <Label>Users</Label>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setNewUser(e.target.value);
                      }}
                    >
                      <option value="Select Region">- Select Users -</option>
                      {users.rows.map((el, i) => (
                        <option key={i} value={el.id}>
                          {el.firstName}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <Button
                    className=" btn-round"
                    color="danger"
                    id="tooltip639225725"
                    onClick={() => {
                      updateHouse();
                    }}
                  >
                    <i className="tim-icons icon-pencil" />
                    Update Record
                  </Button>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Houses;
