import React , {useState, useEffect} from "react";
import axios from 'axios';
import Swal from 'sweetalert2'
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    Button,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    CardTitle,
    Table,  Modal, ModalHeader, ModalBody, ModalFooter, InputGroup,
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    InputGroupAddon,
    InputGroupText,
  } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search  , CSVExport} from 'react-bootstrap-table2-toolkit';
function Regions() {
  const [regions , setRegions] = useState({rows:[]})
  const [name , setRegionName] = useState(null)
  const [latitude , setRegionLatitude] = useState(null)
  const [longitude , setRegionLongitude] = useState(null)
  const [paginationSize, updatepaginationSize] = React.useState(5)
  const [pageStartIndex, updatepageStartIndex] = React.useState(0)
  const [offset, updatepaginationOffset] = React.useState(5)
  const [page, updatePage] = React.useState(0)
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const actionsFormatter = (cell, row) => {
     // this one doesn't update when I trigger "handleAcitionsClick", it just triggers when the component 'mounts', which would be all `null`
    return (
      <Row >
      <Button
      className=" btn-round btn-sm"
      color="primary"
      id="tooltip639225725"
      onClick={() => {
        setEditModal(true);
      }}
    >
      <i className="tim-icons  icon-pencil" /> 
    </Button>
    <Button
      className=" btn-round btn-sm"
      color="danger"
      id="tooltip639225725"
      onClick={() => {
        setDelModal(true);
      }}
    >
      <i className="tim-icons  icon-trash-simple" /> 
    </Button>
    </Row>
    );
};
  const Columns = [{
    dataField: 'id',
    text: 'Region ID'
  }, {
    dataField: 'Name',
    text: 'Name'
  }, {
    dataField: 'latitude',
    text: 'Latitude'
  }, {
    dataField: 'longitude',
    text: 'Longitude'
  } , 
  , {
    dataField: 'charge',
    text: 'Charge'
  }, {
    dataField: 'active',
    text: 'active'
  },
  {
    dataField: "actions",
    text: "Actions",
    formatter: actionsFormatter
}];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  const paginationOption = {
    sizePerPage: 5,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {
        
        
      },
      onSizePerPageChange: function (page, sizePerPage) {
        
        
      }// A numeric array is also available. the purpose of above example is custom the text
    };
  const getResource = (endpoint, setter) => {
    axios({
      url:window.server + endpoint ,
      method: "GET",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      }
    })
    .then( async response => {      
      const data = response.data
      console.log("use", data.data )
      setter(data.data)
    })
  }
  const expandRow = {
    onlyOneExpanding: true,
    renderer: row => (
      <div>
        <p>{ `This Expand row is belong to rowKey ${row.id}` }</p>
        <p>You can render anything here, also you can add additional data on every row object</p>
        <p>expandRow.renderer callback will pass the origin row object to you</p>
      </div>
    )
  };
  const [modal, setModal] = useState(false);
  const [delmodal, setDelModal] = useState(false);
  const [editmodal, setEditModal] = useState(false);
  const [dropDownOpen, setDropdowm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);
  const toggleEdit = () => setEditModal(!editmodal);
  const toggle = () => setModal(!modal);
  const toggleDel = () => setDelModal(!delmodal);
  const  addRegion = () =>{
    const data = {
      "Name":name,
      "longitude":longitude ,
      "latitude":latitude
        
    }
    console.log("data", data);
   axios({
      url:window.server + '/regions' ,
      method: "POST",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      }, 
      data: data
    })
    .then( async response => {     
      Swal.fire({
        title:  ' Success!',
        text: response.data.message, 
        confirmButtonText:  'ok'
      }) 
      getResource('/regions', setRegions)
    }).catch(error => {
      console.log("error", error);
      Swal.fire({
        title:  'Error!',
        text: error.message,
        confirmButtonText:  'ok'
      }) 
    })
  }
  const DeleteRegion= () => {
    axios({
      url: window.server + "/regions/" + selectedItem.id,
      method: "DELETE",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      },
    }).then(async (response) => {
      const data = response;
      Swal.fire({
        title:  ' Success!',
        text: response.data.message,
        confirmButtonText:  'ok'
      }) 
      getResource("/regions", setRegions);
      setDelModal(false)
    }).catch(error => {
      console.log("error", error);
      Swal.fire({
        title:  'Error!',
        text: error.message,
        confirmButtonText:  'ok'
      }) 
    })
  };

const toggleDrop = () => {
  setDropdowm(!dropDownOpen)
}
  useEffect (() => {
    getResource(`/regions?page=${0}&size=${1000000000}`, setRegions)
  }, [])
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                  <CardHeader>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CardTitle tag="h4">All Regions</CardTitle>
                  <Button
                    className=" btn-neutral btn-round btn-simple"
                    color="default"
                    onClick={() => toggle()}
                  >
                    {" "}
                    Add Region
                  </Button>
                </div>
              </CardHeader>
                    <CardBody>
                    <ToolkitProvider
  keyField="id"
  data={ regions.rows }
  columns={ Columns }  
  search
  exportCSV
>
  {
    props => (
      <div>
        <span style={{padding:'5px'}}> <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton></span>
        
        <span><SearchBar { ...props.searchProps } /></span>
        <hr />
        <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
        pagination={ paginationFactory(paginationOption) }
          { ...props.baseProps }
        />
      </div>
    )
  }
</ToolkitProvider>\
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Modal modalClassName="modal-black" isOpen={modal} toggle={toggle}/*  className={className} */>
            <ModalHeader toggle={toggle}>Add Region</ModalHeader>
            <ModalBody>
            <Form >
                    <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label>Name</Label>
                            <Input placeholder="Green Span" type="text"  onChange={(e) =>  setRegionName(e.target.value)  }/>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Latitude</Label>
                            <Input placeholder="0.0000" type="text"  onChange={(e) => { setRegionLatitude(e.target.value)  }}/>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Longitude</Label>
                            <Input placeholder="0.0000" type="text"  onChange={(e) => { setRegionLongitude(e.target.value)  }}/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn-icon btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      setModal(false) 
                      addRegion() 
                    }}
                  >
                    <i className="tim-icons  icon-simple-add" />
                    Add Region
                  </Button>
                </ModalFooter>
        </Modal>
        <Modal modalClassName="modal-black" isOpen={delmodal} toggle={toggleDel}/*  className={className} */>
            <ModalHeader toggle={toggleDel}>Confirmation</ModalHeader>
            <ModalBody>
                <p>Are You sure You want to delete this region ? {selectedItem._id} </p>
            </ModalBody>
            <ModalFooter>
            <Button
              className=" btn-round btn-simple"
              color="default"
              onClick={() => {
                DeleteRegion()
                //console.log(this.state.response.data[0]);
              }}
            >
              <i className="tim-icons icon-simple-remove" />Yes im sure
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          modalClassName="modal-black"
          isOpen={editmodal}
          toggle={toggleEdit} /*  className={className} */
        >
          <ModalHeader toggle={toggleEdit}>Edit</ModalHeader>
          <ModalBody>
            <p>
              Edit
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              className=" btn-round"
              color="danger"
              onClick={() => {
                //console.log(this.state.response.data[0]);
              }}
            >
              <i className="tim-icons icon-simple-pencil" />
              Edit
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Regions;
