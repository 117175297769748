import React, { useState, useEffect } from "react";
import axios from "axios";
import SearchResidence from "./common/SearchResidence";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search  , CSVExport} from 'react-bootstrap-table2-toolkit';
import moment from 'moment'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

function Residence() {
  const [region, setRegions] = useState([]);
  const [modal, setModal] = useState(true);
  const [modal1, setModal1] = useState(false);
  const [Residence, setResidence] = useState(null);

  const [selectedRegion, setSelectedRegion] = useState("");
  const [HouseNumber, setHouseNumber] = useState("");
  const [Building, setBuilding] = useState("");
  const [modalConfirm, setModalConfirm] = useState(false);
  const toggle = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const [page, updatePage] = React.useState(0)
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const actionsFormatter = (cell, row) => {
    return (
    <Row >
      
    {/* <Button
      className=" btn-round btn-sm"
      color="danger"
      id="tooltip639225725"
      onClick={() => {
        setModalConfirm(true);
      }}
    >
      <i className="tim-icons  icon-trash-simple" /> 
      Send Sms Reminder
    </Button> */}
    </Row>
    );
};
const paginationOption = {
  sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
    },
    onSizePerPageChange: function (page, sizePerPage) {
    }// A numeric array is also available. the purpose of above example is custom the text
  };
  const toggleConfirm = () => {
    setModalConfirm(!modalConfirm)
  }; 
  const expandRow = {
    onlyOneExpanding: true,
    renderer: (row) => (
      <div>
        <p>{`House Payments   - ${row.Building.length}`}</p>
        {row.Building ? <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
                            data={ row.Building } columns={ Column2 } /> : ''}
      </div>
    ),
  };
  const Columns = [{
    dataField: 'id',
    text: 'Region ID'
  }, {
    dataField: 'Name',
    text: 'Name'
  }, {
    dataField: 'charge',
    text: 'Charge'
  },{
    dataField: 'active',
    text: 'Active'
  },
  {dataField: "actions",
  text: "Actions",
  formatter: actionsFormatter
}];
const Column2 = [
  {
    dataField: "id",
    text: "Building Id",
  },
  {
    dataField: "Name",
    text: "Building Name",
  },
  {
    dataField: "charge",
    text: "Charge",
  },
  {dataField: "actions",
    text: "Actions",
    formatter: actionsFormatter
  }
];
  const getRegion = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      if (data.data.length > 0) {
        setter(data.data[0].Region);
      }
    });
  };
  const addResidence = () => {
    if (!navigator.geolocation) {
      console.error(`Your browser doesn't support Geolocation`);
    } else {
      navigator.geolocation.getCurrentPosition(savehouse, (error) => {
        console.error(` Geolocation error`);
      });

      function savehouse(position) {
        const { latitude, longitude } = position.coords;
        let data = {
          number: HouseNumber,
          building: Building,
          location: {
            name: selectedRegion,
            type: "point",
            coordinates: [longitude, latitude],
          },
          occupants: [localStorage.user_id],
        };
        axios({
          url: window.server + "/house/add-house",
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          data: data,
        }).then(async (response) => {
          const data = response.data;
        });
      }
    }
  };

  useEffect(() => {
    getRegion(`/assignedRegions?id=${localStorage.user_id}`, setRegions);
  }, []);
  return (
    <>
      <div className="content">
        {region && (
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="title">Regions</h5>
                </CardHeader>
                <CardBody>
                  <CardText />
                  <Col lg="12" md="12">
                    <Card>
                     {/*  <CardHeader>
                        <CardTitle tag="h4">All Regions ...</CardTitle>
                      </CardHeader> */}
                      <CardBody>
                      <ToolkitProvider
  keyField="id"
  data={ region }
  columns={ Columns }  
  search
  exportCSV
>
  {
    props => (
      <div>
        <Row style={{display:'flex', justifyContent:'space-between'}}>
          <div>
              <span style={{padding:'5px'}}> <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton></span>
            
            <span><SearchBar { ...props.searchProps } /></span>
          </div>
          <div>
           {/*  <Button color='primary' onClick={() => {
        setModalConfirm(true);
      }}>Schedule Payment Reminder</Button> */}
          </div>
        
        </Row>
        
        <hr />
        <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
        pagination={ paginationFactory(paginationOption) }
        expandRow={expandRow}
          { ...props.baseProps } 
        />
      </div>
    )
  }
</ToolkitProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </CardBody>
                <CardFooter></CardFooter>
              </Card>
            </Col>
          </Row>
        )}
        {!region && (
          <>
            <div className="content">
              <Row>
                <Col md="12" style={{ height: "70vh" }}>
                  <SearchResidence></SearchResidence>
                </Col>
              </Row>
              <Modal
                isOpen={modal}
                toggle={toggle} /*  className={className} */
              >
                <ModalHeader toggle={toggle}>
                  {" "}
                  No residence on record
                </ModalHeader>
                <ModalBody>
                  Sorry you do not have any regions assigned to you ,
                  Please wait for admin to assign you 
                </ModalBody>
                <ModalFooter>
                  <Button
                    className=" btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    <i className="tim-icons  icon-zoom-split" /> OK
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Residence;
