import React , {useState, useEffect} from "react";
import axios from 'axios';
import SearchResidence from './common/SearchResidence'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CardTitle,
  Table,  Modal, ModalHeader, ModalBody, ModalFooter,
} from "reactstrap";

function Residence() {
  const [region , setRegions] = useState([])
  const [modal, setModal] = useState(true);
  const [modal1, setModal1] = useState(false);
  const [Residence, setResidence] = useState(null);

  const [selectedRegion, setSelectedRegion] = useState("");
  const [HouseNumber, setHouseNumber] = useState("");
  const [Building, setBuilding] = useState("");


  const toggle = () => setModal(!modal);
  const toggle1 = () => setModal1(!modal1);
  const getRegion = (endpoint, setter) => {
    axios({
      url:window.server + endpoint ,
      method: "GET",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      }
    })
    .then( async response => {      
      const data = response.data
      console.log("region", data.data)
      setter(data.data)
    })
  }
  const getResidence = (endpoint, setter) => {
    axios({
      url:window.server + endpoint ,
      method: "GET",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      }
    })
    .then( async response => {      
      const data = response.data
      const user = data.data
        if(user[0].Residence.length > 0) {
          setter(data.data)
        }
    })
  }
  const addResidence = () => {
    if (!navigator.geolocation) {
      console.error(`Your browser doesn't support Geolocation`);
  }else {
    navigator.geolocation.getCurrentPosition(savehouse, (error) =>{
    console.error(` Geolocation error`);
    let position = {
      coords :{
        latitude:"1.0000", 
        longitude:"2.0000"
      }
    }
    savehouse (position)
});

  function savehouse (position){
    const {
      latitude,
      longitude
  } = position.coords;
  console.log( `Your location: (${latitude},${longitude})`);
    let data = {
      "number":HouseNumber,
      "building":Building,
      "latitude":latitude,
      "longitude":longitude,
      "region":selectedRegion, 
    }
   axios({
      url:window.server + '/houses' ,
      method: "POST",      
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-access-token": localStorage.token,
        "X-TENANT-ID":window.tenantId,
        'Authorization': `Bearer ${localStorage.token}`
      }, 
      data: data
    })
    .then( async response => {      
      const data = response.data
      getResidence(`/occupants/?id=${localStorage.user_id}`, setResidence)
      console.log("region", data)
    })
  }
    }
  }

  useEffect (() => {
    getRegion('/regions', setRegions)
    getResidence(`/occupants/?id=${localStorage.user_id}`, setResidence)
  }, [])
  return (
    <>
      <div className="content">
       {Residence && <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <h5 className="title">Houses</h5>
              </CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">My Residence</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Table className="tablesorter" responsive>
                        <thead className="text-primary">
                          <tr>
                            <th>Id</th>
                            <th>House Number</th>
                            <th>Building</th>
                           <th >region</th>
                            {/* <th >Occupants</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {Residence && Residence[0].Residence.map((house, index) => {
                            const { id, Building, number ,Occupant} = house //destructuring
                            return (
                                <tr key={index}>
                                  <td>{id}</td>
                                  <td>{number}</td>
                                  <td>{ Building.name}</td>
                                  <td>{Building.Region.Name}</td>
                                  {/* <td>{Occupant.length}</td>  */}
                                </tr>
                            )
                          })} 
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter>
              </CardFooter>
            </Card>
          </Col>
        </Row>}
        {!Residence && 
        <>
        <div className="content">
          <Row>
            <Col md="12" style={{height:'70vh'}}>
             <SearchResidence></SearchResidence>
             </Col>
            </Row>
            <Modal modalClassName="modal-black"  isOpen={modal} toggle={toggle}/*  className={className} */>
                <ModalHeader toggle={toggle}>   No residence on record</ModalHeader>
                <ModalBody>
                    Sorry you do not have any residence registered with us , Please Add your residence
                </ModalBody>
                <ModalFooter>
                <Button
                    className=" btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      setModal(false) 
                      setModal1(true) 
                    }}
                  >
                    <i className="tim-icons  icon-zoom-split" /> Add Residence
                  </Button>
                </ModalFooter>
            </Modal>
            <Modal modalClassName="modal-black" isOpen={modal1} toggle={toggle1}/*  className={className} */>
                <ModalHeader toggle={toggle1}>Add Your Residence</ModalHeader>
                <ModalBody>
                    <Form >
                    <Row>
                        <Col md="12">
                          <FormGroup>
                            <Label>Region</Label>
                            <select    className="form-control" onChange={(e) => { setSelectedRegion(e.target.value)}} >
                            <option value="Select Region" >- Select Region -</option>
                              {region.map((el, i) => (
                                <option key={i} value={el.id} >
                                  {el.Name}
                                </option>
                              ))}
                              </select>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>Building</Label>
                            <Input placeholder="Building A" type="text"  onChange={(e) =>  setBuilding(e.target.value)  }/>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <Label>House Number</Label>
                            <Input placeholder="A1" type="text"  onChange={(e) => { setHouseNumber(e.target.value)  }}/>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                <Button
                    className="btn-icon btn-round"
                    color="primary"
                    id="tooltip639225725"
                    onClick={() => {
                      setModal1(false) 
                      addResidence() 
                    }}
                  > 
                    <i className="tim-icons  icon-simple-add" /> 
                  </Button>
                </ModalFooter>
            </Modal>
        </div>
        </>
        
        }              
      </div>
    </>
  );
}

export default Residence;
