import React, { useState, useEffect } from "react";
import axios from "axios";
import Receipt from './common/receipt';
import moment from "moment";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  CardTitle,
  Table,  Modal, ModalHeader, ModalBody, ModalFooter,InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search  , CSVExport} from 'react-bootstrap-table2-toolkit'; 
function Payments() {
  const [payments, setPayments] = useState({rows:[]});
  
  const [receiptModal, setReceiptModal] = useState(false);
  const toggleReceipt = () => setReceiptModal(!receiptModal);
  const [selectedItem, setSelectedItem] = useState(false);
  const [paginationSize, updatepaginationSize] = React.useState(5)
  const [pageStartIndex, updatepageStartIndex] = React.useState(0)
  const [offset, updatepaginationOffset] = React.useState(5)
  const [page, updatePage] = React.useState(0)
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;
  const Columns = [{
    dataField: 'id',
    text: 'Payment ID'
  }, {
    dataField: 'method',
    text: 'Method'
  }, {
    dataField: 'amount',
    text: 'Amount'
  }, {
    dataField: 'description',
    text: 'Description'
  }
  ,{
    dataField: 'status',
    text: 'Status'
  }, {
    dataField: 'createdAt',
    text: 'Date'
  }, {
    dataField: 'initiatedBy',
    text: 'Initiated By'
  }];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing { from } to { to } of { size } Results
    </span>
  );
  const paginationOption = {
    sizePerPage: 5,
      lastPageText: '>>',
      firstPageText: '<<',
      nextPageText: '>',
      prePageText: '<',
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: function (page, sizePerPage) {
        
        
      },
      onSizePerPageChange: function (page, sizePerPage) {
        
        
      }// A numeric array is also available. the purpose of above example is custom the text
    };
  
  const getResource = (endpoint, setter) => {
    axios({
      url: window.server + endpoint,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json", 
        "X-TENANT-ID": window.tenantId,
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then(async (response) => {
      const data = response.data;
      console.log("use/......", data.data.rows);
      setter(data.data)
      //if(data.data.length > 0){setter(data.data)};
    });
  };
  useEffect(() => { 
    getResource(
      `/payments/?method=cash&page=${0}&size=${100000000}`, 
      setPayments
    );
  }, []);
  return (
    <>
      <div className="content">
        {payments &&<Row>
          <Col md="12">
            <Card>
              <CardHeader>
              </CardHeader>
              <CardBody>
                <CardText />
                <Col lg="12" md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Agent Collections</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <ToolkitProvider
  keyField="id"
  data={ payments.rows }
  columns={ Columns }  
  search
  exportCSV
>
  {
    props => (
      <div>
        <span style={{padding:'5px'}}> <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton></span>
        
        <span><SearchBar { ...props.searchProps } /></span>
        <hr />
        <BootstrapTable
                            keyField='id'
                            striped
                            bordered={true}
                            wrapperClasses="table-responsive"
        pagination={ paginationFactory(paginationOption) }
          { ...props.baseProps }
        />
      </div>
    )
  }
</ToolkitProvider>
                    </CardBody>
                  </Card>
                </Col>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>}
        {!payments &&<Row>
          <Col md="12">
            <Card style={{display: 'flex', justifyContent: 'center' , alignItems: 'center'}}>
              <CardHeader>
              <p style={{ color: "white" }}>
                  Sorry You do not have any payment records
                </p>
              </CardHeader>
              <CardBody>
                
                <ul>
                  <img src={require("../assets/img/anime3.png").default} alt="Anime"></img>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>}
        <Modal isOpen={receiptModal} toggle={toggleReceipt}  modalClassName="modal-info " style={{maxWidth: "900px"}} >
            <ModalHeader toggle={toggleReceipt}>Receipt</ModalHeader>
            <ModalBody>
                <Receipt props={selectedItem}/>
            </ModalBody>
            <ModalFooter>           
            </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Payments;
